// ** Initial State
const initialState = {
    selectedAIOrganizationId: null,
    selectedAIOrganization: null,
    aiOrganizations: [],
  };
  
  const aiDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_AI_ORGANIZATIONS':
        return {
          ...state,
          aiOrganizations: action.data,
        };
      case 'SET_SELECTED_AI_ORGANIZATION_ID':
        return {
          ...state,
          selectedAIOrganizationId: action.data,
        };
      case 'SET_SELECTED_AI_ORGANIZATION':
        return {
          ...state,
          selectedAIOrganization: action.data,
        };
      default:
        return state;
    }
  };
  
  export default aiDashboardReducer;